import Strings from '../../localization/strings'

const Sandwiches = () => (
  <div className="component__menu">
    <div>
      <h2>{Strings.sandwiches.turkeyHummasClub}</h2>
      <div className="dish">
        <p>{Strings.sandwiches.turkeyHummasClubDescription}</p>
        <a href="https://www.countryliving.com/food-drinks/recipes/a33455/turkey-spicy-hummus-clubs-recipe-ghk0812/">Link</a>
        <hr />
      </div>
    </div>
    <div>
      <h2>{Strings.sandwiches.vietnameseTurkeySandwich}</h2>
      <div className="dish">
        <p>{Strings.sandwiches.vietnameseTurkeySandwichDescription}</p>
        <a href="https://www.countryliving.com/food-drinks/recipes/a34390/vietnamese-turkey-sandwich-recipe-wdy1113/">Link</a>
        <hr />
      </div>
    </div>
    <div>
      <h2>{Strings.sandwiches.honeyAvocadoTurkeyBaconSandwich}</h2>
      <div className="dish">
        <p>{Strings.sandwiches.honeyAvocadoTurkeyBaconSandwichDescription}</p>
        <a href="https://ohsweetbasil.com/honey-avocado-turkey-bacon-sandwiches-recipe/">Link</a>
        <hr />
      </div>
    </div>
    <div>
      <h2>{Strings.sandwiches.turkeyBaconAvocadoGrilledCheese}</h2>
      <div className="dish">
        <p>{Strings.sandwiches.turkeyBaconAvocadoGrilledCheeseDescription}</p>
        <a href="https://www.delish.com/cooking/recipe-ideas/recipes/a46509/turkey-avocado-bacon-grilled-cheese-recipe/">Link</a>
        <hr />
      </div>
    </div>
    <div>
      <h2>{Strings.sandwiches.turkeyMozzarellaKalePestoPanini}</h2>
      <div className="dish">
        <p>{Strings.sandwiches.turkeyMozzarellaKalePestoPaniniDescription}</p>
        <a href="https://www.delish.com/cooking/recipe-ideas/recipes/a46640/turkey-mozzarella-kale-pesto-panini-recipe/">Link</a>
        <hr />
      </div>
    </div>
  </div>
)

export default Sandwiches
