import Snowfall from 'react-snowfall'
import Strings from '../../localization/strings'
import { DropdownMenu, Menu, Sandwiches } from '../../components'
import Events from '../../constants'
import parseQueryString from '../../utils'

const App = (): JSX.Element => {
  const queryParams = parseQueryString(window.location.search)
  const event: Events = (queryParams.menu as Events) || 'arepas'
  return (
    <div className={`app ${event}`}>
      {event === 'christmas' ? <Snowfall /> : null}
      <DropdownMenu />
      <div className="menu">
        <div className="header">
          <h1>{Strings.event[event]}</h1>
          <h4>{Strings.yerba[event]}</h4>
        </div>
        {event === 'sandwiches' ? <Sandwiches /> : null}
        <Menu event={event} />
        <div className="footer">
          <h4>{Strings.address}</h4>
          <h5>{Strings.city}</h5>
        </div>
      </div>
    </div>
  )
}

export default App
