import './styles.scss'

const DropdownMenu = () => (
  <div className="dropdown">
    <button className="dropbtn">
      <img src="/images/menu.svg" alt="menu" />
    </button>
    <div className="dropdown-content">
      <a href="/?menu=christmas">Christmas</a>
      <a href="/?menu=thanksgiving">Thanksgiving</a>
      <a href="/?menu=sandwiches">New Years</a>
      <a href="/?menu=arepas">Arepas</a>
    </div>
  </div>
)
export default DropdownMenu
