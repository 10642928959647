const Strings = {
  event: {
    thanksgiving: 'Thanksgiving',
    christmas: 'Navidad',
    sandwiches: 'New Years',
    arepas: 'Arepas',
  },
  cocktails: 'Cocktails',
  yerba: {
    thanksgiving: '🙏 ✨ 🙏 ✨ 🙏',
    christmas: '🎄',
    sandwiches: '🦃 🥪',
    arepas: 'de Simon',
  },
  address: 'Parrillalandia',
  appetizer: 'Appetizer',
  side: 'Side',
  entre: 'Main',
  dessert: 'Dessert',
  drinks: 'Drinks',
  guests: 'Guests',
  signup: 'Signup',
  save: 'Guardar',
  nope: 'Nope',
  bringAnything: 'Do you plan on bringing anything?',
  nothingOnTheMenuYet: 'Nothing on the menu yet',
  city: 'Villa Crespo, Buenos Aires',
  preparedBy: 'Prepared by:',
  edit: 'Edit',
  cancel: 'Cancel',
  delete: 'Delete',
  errors: {
    name: 'Name is required.',
    dish: 'Dish is required.',
    description: 'Description is required.',
    updating: 'Something went wrong when trying to update',
  },
  sandwiches: {
    turkeyHummasClub: 'Turkey and Spicy Hummus Clubs',
    turkeyHummasClubDescription: 'Typical lettuce tomato, bacon turkey club with a spicy hummus twist',
    vietnameseTurkeySandwich: 'Vietnamese Turkey Sandwich',
    vietnameseTurkeySandwichDescription:
      'Dressed with siracha mayonnaise, this Vietnamese twist on a turkey sandwich is topped with salami, fresh ciliantro and home made pickled cucumbers, radishes, green onions and carrots', // eslint-disable-line max-len
    honeyAvocadoTurkeyBaconSandwich: 'Honey Avocado Turkey Bacon Sandwiche',
    honeyAvocadoTurkeyBaconSandwichDescription: 'The secret to this bacon and turkey sandwich is the avacado honey dressing',
    turkeyBaconAvocadoGrilledCheese: 'Turkey-Bacon-Avocado Grilled Cheese',
    turkeyBaconAvocadoGrilledCheeseDescription: 'Turkey Bacon Avocado Grilled Cheese - no further explanation needed',
    turkeyMozzarellaKalePestoPanini: 'Turkey, Mozzarella, and Kale Pesto Panini',
    turkeyMozzarellaKalePestoPaniniDescription: 'Sounds kinda healthy till you see all the mozzarella!',
  },
}

export default Strings
